import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa";
const Footer = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className=" px-3  lg:pt-[30px] lg:px-[150px] text-white bg-[#111] z-50">
      <div className=" lg:grid grid-cols-4  gap-4 ">
        <div className="flex flex-col justify-start items-start text-[12px] col-span-1 gap-3">
          <img
            className="lg:w-[70px] p-5 px-8 lg:p-0 lg:px-0 lg:m-2"
            src="/t1logo.png"
            alt=""
          />

          <Link to={"mail:info@thetivan.com"}>info@thetivan.com</Link>
          <div className="flex flex-col lg:gap-1 gap-2 ">
            <p className="flex">
              22.st,Al Quez,AL QOUZ Industrial area 3{" "}
              <p className="text-red-500">Dubai,U.A.E</p>
            </p>
            <Link to={"tel:+971 542957751"}>+971 542957751</Link>
            <Link to={"tel:+971 545207902"}>+971 545207902</Link>
            <Link to={"tel:+971 588506469"}>+971 588506469</Link>
          </div>
          <div>
            <p className="flex lg:mb-1 mb-2">
              NO.104, Swinford Rd,.Birmingham B29 5SX
              <p className="text-red-500">England</p>
            </p>
            <Link to={"tel:+44 7403043461"}>+44 7403043461</Link>
          </div>
        </div>
        <div className="mt-[20px] grid lg:grid-cols-3 grid-cols-2 col-span-3 gap-4">
          <nav className=" lg:col-span-2 flex flex-col justify-between mx-4">
            <ul className="flex flex-col justify-start items-start text-[15px] lg:flex-row lg:justify-between ">
              <li>
                <Link to={"/"}>{t("home")}</Link>
              </li>
              <li>
                <Link to={"/products"}>{t("products")}</Link>
              </li>
              <li>
                <Link to={"/projects"}>{t("projects")}</Link>
              </li>
              <li>
                <Link to={"/about"}>{t("contact")}</Link>
              </li>
            </ul>
          </nav>
          <div>
            <div className="grid grid-cols-5 w-fit justify-items-center  items-start  lg:justify-end gap-2">
              <Link className="bg-red-600 rounded-full p-1 lg:m-3 lg:text-[20px] lg:p-2 text-white ">
                <FaInstagram />
              </Link>
              <Link className="bg-red-600 rounded-full p-1 lg:m-3 lg:text-[20px] lg:p-2 text-white">
                <FaFacebookF />
              </Link>
              <Link className="bg-red-600 rounded-full p-1 lg:m-3 lg:text-[20px] lg:p-2 text-white">
                <FaTwitter />
              </Link>
              <Link className="bg-red-600 rounded-full p-1 lg:m-3 lg:text-[20px] lg:p-2 text-white">
                <FaYoutube />
              </Link>
              <Link className="bg-red-600 rounded-full p-1 lg:m-3 lg:text-[20px] lg:p-2 text-white">
                <FaLinkedinIn />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center py-4 gap-8">
        <p className="text-[10px] text-gray-400  lg:text-[13px]">
          T1 Vision. All rights reserved
        </p>
        <a
          href="https://sirir.ir"
          target={"_blank"}
          className="text-[10px] text-gray-400  lg:text-[13px] flex items-center gap-2"
        >
          Powered By{" "}
          <img
            src="https://mbabz.com/wp-content/themes/SecretStrategy/assets/img/logo.svg"
            className="w-[29px]  rounded-full invert-[70%]"
          />
          Sirir
        </a>
      </div>
    </div>
  );
};

export default Footer;
